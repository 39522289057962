.cardsContainer {
    display: flex;
    /*position: relative;*/
}

.buttonText {
    font-size: 0.8rem !important;
}

.aPCard {
    height: 100%;
    margin-top: 1.5rem;
    border: 3px solid rgb(82, 36, 127) !important;
    /*border: 3px solid #00406E !important;*/
}

.aPTitle {
    font-family: Roboto;
    text-transform: uppercase;
    margin-bottom: 0.1rem !important;
    color: rgb(82, 36, 127);
}

.aPSubtitle {
    font-family: Roboto;
    margin-top: 0 !important;
    margin-bottom: 1rem
}

.titleGrid {
    display: flex;
    justify-content: center;
    align-items: center
}

.itemCardGrid {
    display: flex;
    justify-content: center;
    align-items: center
}

.clickableTitle {
    cursor: pointer;
    max-height: 100%;
    height: 100%;
}

    .clickableTitle:hover .MuiPaper-outlined {
        background-color: rgba(50, 50, 50, 0.1) !important;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms
    }

.aPDescription b {
    color: red;
}

.aPDescription {
    font-family: Roboto;
    letter-spacing: 1.25px;
    font-size: 1.25rem;
    margin-top: 0;
    text-align: justify;
    color: rgb(99, 102, 106);
}

.cardActions {
    display: flex;
    align-items: flex-end;
    justify-content: left;
}

.cardAction {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%
}

.buttonAction {
    width: 100%;
    background-color: rgb(82, 36, 127) !important;
    border: none;
    color: white !important;
}

    .buttonAction:hover {
        background-color: rgb(99, 102, 106) !important;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        border: none;
    }

.buttonNormalCase {
    text-transform: none !important;
}

.loginIcon {
    transform: scale(3);
    color: rgb(82, 36, 127)
}
